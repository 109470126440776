import React from 'react'


const Test: React.FC = () => {
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-12">
          <i className="fas fa-info-circle mr-2"></i>
          Test Description
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="card">
            <header className="card-header">Test Title</header>
            <section className="card-body">
              <h4>Test Content</h4>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Test