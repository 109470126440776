import React, { useState, useRef } from 'react'

import { SignatureCanvas } from "component/signaturePad"

const Demo: React.FC = props => {
  const [signed, setSigned] = useState(false)
  const [signature, setSignature] = useState('')
  const next = (dataURL: string) => {
    setSignature(dataURL)
    setSigned(true)
  }
  const goBack = () => setSigned(false)

  return (
    <div className='container'>
      <div className="row mt-5">
        <div className="col-12">
          <i className="fas fa-info-circle mr-2"></i>
          請在下方<strong>「簽名欄」</strong>書寫您的名字
        </div>
      </div>
      <div className="row mt-4">
        {signed ? <Result dataUrl={signature} goBack={goBack} /> : <Pad next={next} />}
      </div>
    </div>
  )
}

interface PadProps {
  next: (dataURL: string) => void
}

const Pad: React.FC<PadProps> = ({ next }) => {
  const canvasEl = useRef<SignatureCanvas>(null)
  const handleClear = () => canvasEl.current!.clear()
  const handleUndo = () => canvasEl.current!.undo()
  const handleNext = () => next(canvasEl.current!.toDataURL())

  return (
    <div className="col-12">
      <div className="card">
        <header className="card-header"><strong>簽名欄</strong></header>
        <section className="card-body w-100" style={{ height: '300px' }}>
          <SignatureCanvas ref={canvasEl} />
        </section>
        <footer className="card-footer">
          {/* eslint-disable-next-line */}
          <a href="#" className="card-link" onClick={handleClear}>清除</a>
          {/* eslint-disable-next-line */}
          <a href="#" className="card-link" onClick={handleUndo}>Undo</a>
        </footer>
      </div>
      <div className="d-flex flex-row-reverse mt-5">
        <button type='button' className="btn btn-primary" onClick={handleNext}>下一步</button>
      </div>
    </div>
  )
}

interface ResultProps {
  dataUrl: string
  goBack: () => void
}

const Result: React.FC<ResultProps> = ({ dataUrl, goBack }) => {
  return (
    <div className="col-12">
      <h2>你的簽名是...</h2>
      <div>
        <img className="w-100" alt="簽名" src={dataUrl} />
      </div>
      <button type="button" className="btn btn-primary" onClick={goBack}>上一步</button>
    </div>
  )
}

export default Demo