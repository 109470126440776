import React from 'react'
import { NavLink } from 'react-router-dom'

const TopBar: React.FC = props => (
  <nav className="navbar navbar-expand-md navbar-dark bg-dark">
    <a className="navbar-brand" href="http://www.lustertech.net">達暉</a>
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>

    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav mr-auto">
        <NavLink className="nav-item nav-link" to="/signatureDemo" activeClassName="active">簽名展示</NavLink>
        <NavLink className="nav-item nav-link" to="/barcodeReader" activeClassName="active">條碼掃描</NavLink>
        <NavLink className="nav-item nav-link" to="/test" activeClassName="active">測試</NavLink>
      </ul>
    </div>
  </nav>
)

export default TopBar