import React, { Component } from 'react'
import SignaturePad from 'signature_pad'

export default class SignatureCanvas extends Component {
  _canvas = React.createRef<HTMLCanvasElement>()
  _sigPad: any

  _resizeCanvas = () => {
    const canvas = this._canvas.current!
    const ratio = Math.max(window.devicePixelRatio || 1, 1)

    canvas.width = canvas.offsetWidth * ratio
    canvas.height = canvas.offsetHeight * ratio
    canvas.getContext('2d')!.scale(ratio, ratio)

    this.clear()
  }

  componentDidMount = () => {
    this._sigPad = new SignaturePad(this._canvas.current!)
    this._resizeCanvas()
    this.on()
  }

  componentWillUnmount = () => this.off()

  render = () => <canvas ref={this._canvas} style={{ width: '100%', height: '100%' }} />

  undo = () => {
    const data = this._sigPad.toData()
    if (data) {
      data.pop()
      this._sigPad.fromData(data)
    }
  }

  /**
   * SignaturePad wrapper functions
   */

  toDataURL = (type?: string, encoderOptions?: number) => this._sigPad.toDataURL(type, encoderOptions)
  // fromDataURL = (dataURL, options) => this._sigPad.fromDataURL(dataURL, options)

  // toData = () => this._sigPad.toData()
  // fromData = (pointGroups) => this._sigPad.fromData(pointGroups)

  clear = () => this._sigPad.clear()
  // isEmpty = () => this._sigPad.isEmpty()

  off = () => {
    window.removeEventListener('resize', this._resizeCanvas)
    return this._sigPad.off()
  }

  on = () => {
    window.addEventListener('resize', this._resizeCanvas)
    return this._sigPad.on()
  }
}