import React from 'react'

import FixedBarcodeReader, { BarcodeType } from './FixedBarcodeReader'

const readers = [BarcodeType.Code128]

const isImeiValid = (imei: string) => {
  if (!/^[0-9]{15}$/.test(imei)) { return false }

  let sum = 0, factor = 2, checkDigit, multipliedDigit
  for (var i = 13, li = 0; i >= li; i--) {
    multipliedDigit = parseInt(imei.charAt(i), 10) * factor;
    sum += (multipliedDigit >= 10 ? ((multipliedDigit % 10) + 1) : multipliedDigit)
    factor === 1 ? factor++ : factor--
  }
  checkDigit = ((10 - (sum % 10)) % 10);

  return !(checkDigit !== parseInt(imei.charAt(14), 10))
}

interface ReaderProps {
  id: string
  cb(code: string): void
}

const Reader: React.FC<ReaderProps> = ({ id, cb }) => {
  return (
    <FixedBarcodeReader id={id} readers={readers} validator={isImeiValid} cb={cb} />
  )
}

export default Reader