import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.css'

import { deviceStore } from 'store'
import { TopBar } from "./component/layout"
import { SignatureDemo, Test, BarcodeReaderDemo } from "./page"

const App: React.FC = () => {

  useEffect(() => {
    window.addEventListener('resize', deviceStore.detectDevice)
    return () => window.removeEventListener('resize', deviceStore.detectDevice)
  }, [])

  return (
    <Router>
      <TopBar />

      <Switch>
        <Route path="/signatureDemo" component={SignatureDemo} />
        <Route path="/test" component={Test} />
        <Route path="/barcodeReader" component={BarcodeReaderDemo} />
        <Redirect to="/signatureDemo" />
      </Switch>
    </Router>
  );
}

export default App