import React, { useState } from 'react'

import ImeiBarcodeReader from 'component/quagga/ImeiBarcodeReader'

const Test: React.FC = () => {

  const [barcodeResult, setBarcodeResult] = useState('')
  const handleInputChange = (e: any) => { setBarcodeResult(e.target.value) }

  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-12">
          <i className="fas fa-info-circle mr-2"></i>
          請點擊下方<strong>「掃描按鈕」</strong>進行 IMEI 條碼掃描，iOS 裝置請用 safari 瀏覽器。
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="card">
            <header className="card-header">行動裝置資訊</header>
            <section className="card-body">
              <form className="form-inline">
                <div className="form-group mb-2">
                  <input
                    type="text" className="form-control" id="input_IMEI"
                    value={barcodeResult} placeholder="請輸入 IMEI"
                    onChange={handleInputChange}
                  />
                </div>
                <button type="button" className="btn btn-primary ml-2 mb-2"
                  data-toggle="modal" data-target="#barcodeReader">
                  掃描
                </button>
              </form>
            </section>
            <ImeiBarcodeReader id="barcodeReader" cb={setBarcodeResult} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Test
